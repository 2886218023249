const QrTelegram = () => {

    return (
        <div className="">

            <img
                className="w-[150px]"
                alt="qrlink grupo de telegram la guarida"
                src="https://i.imgur.com/ef6sOIm.png"
            />
            <p className="
            text-center text-sm text-blue-500">Grupo de Telegram</p>
        </div>
    )
}

export default QrTelegram