const H1Titles = props => {
    const { h1Title } = props

    return (
        <h1
           


            className="text-3xl text-white p-7 font-extrabold  decoration-8 decoration-cyan-700 "

        >
            {h1Title}
        </h1>
    )
}

export default H1Titles